//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "../..";
import router from '@/router'


class TransformacionServices {
  
 
 
  TransformacionActualizar(data) {
    return http.post("/inventario/transformacion/transformacionActualizar", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }


  transformacionListarPaginado(params) {  
    return http.get("/inventario/transformacion/transformacionListarPaginado", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  } 
  
  
  listarMotivoTransformacion(params) {  
    return http.get("/inventario/transformacion/listarMotivoTransformacion", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }  


  anularTransformacion(data) {
    return http.post("/inventario/transformacion/transformacionAnular", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  reporteListarTransformacionDetalle(params) {  
    return http.get("/inventario/transformacion/reporteListarTransformacionDetalle", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }  
  reporteListarTransformacion(params) {  
    return http.get("/inventario/transformacion/reporteListarTransformacion", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }  
 
  
   
}

export default new TransformacionServices();