//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "../..";
import router from '@/router'


class AjusteServices {
  
  

  consultarConteoExpress(params) {  
    return http.get("/inventario/conteo/consultarConteoExpress", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  } 
  

  conteoActualizar(data) {
    return http.post("/inventario/conteo/conteoActualizar", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }

  AjusteActualizar(data) {
    return http.post("/inventario/ajuste/ajusteActualizar", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }


  ajusteListarPaginado(params) {  
    return http.get("/inventario/ajuste/ajusteListarPaginado", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  } 
  conteoListarPaginado(params) {  
    return http.get("/inventario/ajuste/conteoListarPaginado", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  } 
  conteoListarDetalle(params) {  
    return http.get("/inventario/ajuste/conteoListarDetalle", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  } 
  
  
  
  listarMotivoAjuste(params) {  
    return http.get("/inventario/ajuste/listarMotivoAjuste", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }  


  anularAjuste(data) {
    return http.post("/inventario/ajuste/ajusteAnular", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }

  conteoAnular(data) {
    return http.post("/inventario/conteo/conteoAnular", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  conteoGenerarAjustes(data) {
    return http.post("/inventario/conteo/conteoGenerarAjustes", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  
  
  subirConteo(data) {
    return http.post("/inventario/conteo/upload", data, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    })
  } 

  reporteListarAjustenDetalle(params) {  
    return http.get("/inventario/ajuste/reporteListarAjustenDetalle", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }  
  reporteListarAjuste(params) {  
    return http.get("/inventario/ajuste/reporteListarAjuste", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }  
 
  motivoActualizar(data) {
    return http.post("/inventario/ajuste/motivoActualizar", data)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  
   
}

export default new AjusteServices();