
<template>
  <span></span>
</template>
<script>
import store from '@/store'
import jsPDF from 'jspdf'

import FuncionesGenerales from '@/funciones/funciones'
import autoTable from 'jspdf-autotable'
import moment from 'moment' 
export default {
  setup() {
    const rellenarEspacios = (valor, max = 15) => {
      let resp = ''

      if (valor.length < max) {
        const dif = max - valor.length
        let cant = 0
        let par = false
        if (dif.mod % 2 == 0) {
          par = true
          cant = dif / 2
        } else {
          cant = (dif - 1) / 2
        }
        cant++
        let espacios = ''

        for (let index = 0; index < cant; index++) {
          espacios += ' '
        }

        resp = espacios + (par ? ' ' : '') + valor + espacios
      } else {
        resp = valor
      }
      return resp.toUpperCase()
    }

    const datosInit = {
      nombreDocumento: 'nombre',
      cabecera1: {
        visible: true,
      },
      titulo: {
        visible: true,
        posicion: 'center',
        texto: 'Recibo #1000',
        TamanoLetras: 14,
        color: '#FF0000',
      },
      etiqueta: {
        visible: true,
        valor: 'PAGADA CON TODO',
        color: '#FF0000',
      },
      numeroDocumento: {
        texto: 'Compra Fac:',
        valor: '0000000001',
      },
      numeroControl: {
        texto: 'Nro',
        valor: 'COM-00-000939',
      },
      numeroFecha: {
        texto: 'Fecha Factura',
        valor: '2024-01-16',
      },
      sub1: {
        linea1: [
          { texto: 'Proveedor:', valor: 'Sigo s.a' },
          { texto: 'Rif:', valor: 'j-15414-1541' },
          { texto: 'Telf:', valor: '+58 201 302 22' },
          { texto: 'Direccion:', valor: 'CALLE JULIO LUIS BUFON  CASA NRO S/N SECTOR LOS P' },
        ],
        linea2: [],
      },
      sub2: {
        linea1: [
          {
            texto: 'Area:',
            valor: 'Cocina principal',
          },
          { texto: 'Almacen:', valor: 'Almacen principal' },
          { texto: 'Estado:', valor: 'Registrado' },
        ],
        linea2: [
          {
            texto: 'Empleado:',
            valor: 'Angel Jose Labori Campos',
          },
          { texto: 'Fecha Creacion:', valor: '2024-02-06' },
          { texto: 'Hora Creacion:', valor: '13:01:01' },
        ],
      },
      detalles: {
        cabecera: [
          { texto: 'Id', posicion: '45', alineacion: 'left' },
          {
            texto: 'Producto',
            posicion: '80',
            alineacion: 'left',
            justificar: { maxWidth: 150, align: 'justify', maximoItems: 20 },
          },
          { texto: 'Cant', posicion: '240', alineacion: 'right' },
          { texto: 'CostUnd', posicion: '300', alineacion: 'right' },
          { texto: 'Imp', posicion: '360', alineacion: 'right' },
          { texto: 'Des', posicion: '400', alineacion: 'right' },
          { texto: 'IVA', posicion: '460', alineacion: 'right' },
          { texto: 'Total', posicion: '500', alineacion: 'right' },
          { texto: 'Almacen', posicion: '555', alineacion: 'right' },
        ],

        cuerpo: [
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
          ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', '$12.82', '0,00%', '0,00%', '16,00%', '$446.14', 'ALIMENTOS'],
        ],
      },
      pie: {
        nota: {
          texto: 'Nota:',
          valor:
            'Nota complet a CALLE JULIO LUIS BUFON  CASA NRO S/N SECTOR LOS P CALLE JULIO LUIS BUFON  CASA NRO S/N SECTOR LOS P CALLE JULIO LUIS BUFON  CASA NRO S/N SECTOR LOS P',
        },
        totales: [
          {
            texto: 'SubTotal:',
            valor: '0,0000',
          },
          {
            texto: 'Impuesto:',
            valor: '0,0000',
          },
          {
            texto: 'IVA:',
            valor: '0,0000',
          },
          {
            texto: 'Descuento:',
            valor: '0,0000',
          },
          {
            texto: 'Total:',
            valor: '0,0000',
          },
        ],
      },
    }
    const TamanoLetras = {
      texto: 10,
      titulo: 14,
      subtitulo: 12,
      comentario: 10,
    }

    var lineas = {
      cabecera1: 30,
      cabecera2: 75,
      sub1: 0,
      sub2: 0,
      cuerpo1: 0,
    }
    const reset = () => {
      lineas = {
        cabecera1: 30,
        cabecera2: 75,
        sub1: 0,
        sub2: 0,
        cuerpo1: 0,
      }
    }

    const sumLinea = (tipo, valor) => {
      if (tipo == 1) {
        lineas.cabecera1 += valor
        return lineas.cabecera1
      } else if (tipo == 2) {
        lineas.cabecera2 += valor
        return lineas.cabecera2
      } else if (tipo == 3) {
        lineas.sub1 += valor
        return lineas.sub1
      } else if (tipo == 4) {
        lineas.sub2 += valor
        return lineas.sub2
      } else if (tipo == 5) {
        lineas.cuerpo1 += valor
        return lineas.cuerpo1
      } else {
        return 0
      }
    }
    const proporcionLogo = (config.VUE_APP_PROPORCION_LOGO || process.env.VUE_APP_PROPORCION_LOGO).split(',')
    const generarPDF = (datos = datosInit) => {
      const orientacion = datos.orientacion ? datos.orientacion : 'p'
      reset()
      console.log(store.state.configuracionGeneral)
      const pdf = new jsPDF(datos.orientacion ? datos.orientacion : 'p', 'pt', 'A4', true)

      if (datos.etiqueta && datos.etiqueta.visible) {
       
        pdf.setDrawColor(datos.etiqueta.color)
        pdf.setLineWidth(30)
        pdf.line(pdf.internal.pageSize.width - 125, -20, pdf.internal.pageSize.width + 20, 125)
        pdf.setLineWidth(1)
        pdf.setTextColor(255, 255, 255)
        pdf.setDrawColor(0, 0, 0)
        pdf.setFont('Courier', 'bold')
        pdf.text(rellenarEspacios(datos.etiqueta.valor, 15), pdf.internal.pageSize.width - 115, 0, {
          align: 'left',
          angle: -45,
        })
      } 

      pdf.setFontSize(TamanoLetras.texto)
      pdf.setTextColor('#5E5669')
      pdf.setFont('Helvetica', 'bold')

      //  datos de la empresa
      if (datos.cabecera1.visible == true) {
        pdf.addImage(
          store.state.configuracionGeneral.logo_empresa_2,
          'JPEG',
          40,
          sumLinea(1, 0),
          proporcionLogo[1] * 40,
          proporcionLogo[0] * 40,
        )
        pdf.setFontSize(10)
        pdf.text(store.state.configuracionGeneral.nombre_empresa, 40, lineas.cabecera1 + (proporcionLogo[0] * 40 + 12))
        pdf.setFontSize(10)
        pdf.setFont('Helvetica', 'normal')
        pdf.text(store.state.configuracionGeneral.rif_empresa, 40, lineas.cabecera1 + proporcionLogo[0] * 40 + 12 + 12)
        pdf.text(
          FuncionesGenerales.camelCase(store.state.configuracionGeneral.direccion_empresa_1, true),
          450,
          sumLinea(2, -30),
          null,
          null,
          'center',
        )
        pdf.text(
          FuncionesGenerales.camelCase(store.state.configuracionGeneral.direccion_empresa_2, true),
          450,
          sumLinea(2, 12),
          null,
          null,
          'center',
        )
        pdf.text(
          FuncionesGenerales.camelCase(store.state.configuracionGeneral.direccion_empresa_3),
          450,
          sumLinea(2, 12),
          null,
          null,
          'center',
        )
        pdf.text(
          FuncionesGenerales.camelCase(store.state.configuracionGeneral.telefono_empresa_1),
          450,
          sumLinea(2, 12),
          null,
          null,
          'center',
        )
        pdf.setFontSize(14)
        lineas.cabecera1 = lineas.cabecera1 + 55 + 12

        pdf.text(
          datos.numeroControl.texto + ' ' + datos.numeroControl.valor,
          450,
          sumLinea(2, 28),
          null,
          null,
          'center',
        )

        pdf.setFontSize(10)
        pdf.setFillColor(255, 255, 255)
        pdf.roundedRect(350, lineas.cabecera2 + 5, 210, 30, 3, 3)

        pdf.text(datos.numeroFecha.texto, 450, sumLinea(2, 17), null, null, 'center')

        pdf.setFontSize(12)
        pdf.text(datos.numeroFecha.valor, 450, sumLinea(2, 13), null, null, 'center')
      }
      //  datos de la empresa
      pdf.setFont('Helvetica', 'normal')
      //  cabecera 2

      pdf.setTextColor('#5E5669')
      pdf.setFont('Helvetica', 'bold')
      pdf.setFontSize(14)
      pdf.text(datos.numeroDocumento.texto, 40, sumLinea(1, 40))

      pdf.setTextColor('#FF0000')
      pdf.setFont('Helvetica', 'bold')
      pdf.setFontSize(16)
      pdf.text(datos.numeroDocumento.valor, 40 + datos.numeroDocumento.texto.length * 7.5, sumLinea(1, 0))

      pdf.setFillColor(255, 255, 255)

      /* Sub Linea  1 */
      let rectsub1 = lineas.cabecera1
      lineas.cabecera2 = lineas.cabecera1
      const maxWidthJust1 = datos.sub1.linea2.length > 0 ? 215 : 430
      sumLinea(1, 11)
      datos.sub1.linea1.forEach(item => {
        pdf.setFont('Helvetica', 'bold')
        pdf.setTextColor('#5E5669')
        pdf.setFontSize(9)
        pdf.text(item.texto, 45, sumLinea(1, 12))
        pdf.setFont('Helvetica', 'normal')
        pdf.text(item.valor, 45 + item.texto.length * 6, sumLinea(1, 0), { maxWidth: maxWidthJust1, align: 'justify' })

        if (maxWidthJust1 == 430) {
          if (item.valor.length / 74 > 1) {
            sumLinea(1, Math.ceil(item.valor.length / 74) * 12)
            alert(Math.ceil(item.valor.length / 74))
          }
        } else {
          if (item.valor.length / 37 > 1) {
            sumLinea(1, Math.ceil(item.valor.length / 37) * 12)
          }
        }
        pdf.setTextColor('#5E5669')
      })

      sumLinea(2, 11)
      datos.sub1.linea2.forEach(item => {
        pdf.setFont('Helvetica', 'bold')
        pdf.setTextColor('#5E5669')
        pdf.setFontSize(9)
        pdf.text(item.texto, 305, sumLinea(2, 12))
        pdf.setFont('Helvetica', 'normal')
        pdf.text(item.valor, 305 + item.texto.length * 6, sumLinea(2, 0), {
          maxWidth: maxWidthJust1 - item.texto.length * 6 + 35,
          align: 'justify',
        })

        pdf.setTextColor('#5E5669')

        if (item.valor.length / 37 > 1) {
          sumLinea(2, Math.ceil(item.valor.length / 37) * 12)
        }
      })

      lineas.cabecera1 = lineas.cabecera2 > lineas.cabecera1 ? lineas.cabecera2 : lineas.cabecera1
      lineas.cabecera2 = lineas.cabecera2 > lineas.cabecera1 ? lineas.cabecera2 : lineas.cabecera1

      const maxRect1 = lineas.cabecera1
      pdf.roundedRect(40, rectsub1 + 10, 520, maxRect1 - rectsub1, 3, 3)

      /* Sub Linea  1 */

      sumLinea(1, 3)
      /* Sub Linea  2 */

      if (datos.sub2.linea1.length > 0 || datos.sub2.linea2.length > 0) {
        let rectsub2 = lineas.cabecera1
        lineas.cabecera2 = lineas.cabecera1
        const maxWidthJust = datos.sub2.linea2.length > 0 ? 215 : 430
        sumLinea(1, 11)
        datos.sub2.linea1.forEach(item => {
          pdf.setFont('Helvetica', 'bold')
          pdf.setTextColor('#5E5669')
          pdf.setFontSize(9)
          pdf.text(item.texto, 45, sumLinea(1, 12))
          pdf.setFont('Helvetica', 'normal')
          pdf.text(item.valor, 45 + item.texto.length * 6, sumLinea(1, 0), { maxWidth: maxWidthJust, align: 'justify' })

          if (maxWidthJust == 430) {
            if (item.valor.length / 74 > 1) {
              sumLinea(1, Math.ceil(item.valor.length / 74) * 12)
            }
          } else {
            if (item.valor.length / 37 > 1) {
              sumLinea(1, Math.ceil(item.valor.length / 37) * 12)
            }
          }

          pdf.setTextColor('#5E5669')
        })

        sumLinea(2, 11)
        datos.sub2.linea2.forEach(item => {
          pdf.setFont('Helvetica', 'bold')
          pdf.setTextColor('#5E5669')
          pdf.setFontSize(9)
          pdf.text(item.texto, 305, sumLinea(2, 12))
          pdf.setFont('Helvetica', 'normal')
          pdf.text(item.valor, 305 + item.texto.length * 6, sumLinea(2, 0), {
            maxWidth: maxWidthJust - item.texto.length * 6 + 35,
            align: 'justify',
          })

          pdf.setTextColor('#5E5669')

          if (item.valor.length / 37 > 1) {
            sumLinea(2, Math.ceil(item.valor.length / 37) * 12)
          }
        })

        lineas.cabecera1 = lineas.cabecera2 > lineas.cabecera1 ? lineas.cabecera2 : lineas.cabecera1
        lineas.cabecera2 = lineas.cabecera2 > lineas.cabecera1 ? lineas.cabecera2 : lineas.cabecera1

        const maxRect = lineas.cabecera1

        pdf.roundedRect(40, rectsub2 + 10, 520, maxRect - rectsub2, 3, 3)
      }
      /* Sub Linea  1 */

      pdf.roundedRect(
        40,
        lineas.cabecera1 + 12,
        520,
        datos.detalles.cuerpo.length * 20 <= 380
          ? 776 - datos.pie.totales.length * 20 - (lineas.cabecera1 + 20)
          : pdf.internal.pageSize.height - 50 - lineas.cabecera1,
        3,
        3,
      )
      pdf.roundedRect(40, lineas.cabecera1 + 32, 520, 0, 0, 0)
      pdf.setFont('Helvetica', 'bold')
      sumLinea(1, 25)
      datos.detalles.cabecera.forEach(item => {
        pdf.text(item.texto, item.posicion, sumLinea(1, 0), item.alineacion)
      })
      /*
      pdf.text('Codigo', 45, sumLinea(1, 25), 'left')
      pdf.text('Nombre Articulo', 120, sumLinea(1, 0), 'left')
      pdf.text('Prec', 380, sumLinea(1, 0), 'right')
      pdf.text('Cant', 430, sumLinea(1, 0), 'right')
      pdf.text('IVA', 490, sumLinea(1, 0), 'right')
      pdf.text('Total', 555, sumLinea(1, 0), 'right')
*/

      datos.detalles.cuerpo.forEach((item, ix) => {
        pdf.setFont('Helvetica', 'normal')
        pdf.setFontSize(7)
        let linea_temp = sumLinea(1, 20)
        datos.detalles.cabecera.find((col, index) => {
          const just = col.justificar ? col.justificar : col.alineacion
          pdf.text(item[index], col.posicion, linea_temp, just)
          if (col.justificar && Math.ceil(item[index].length / col.justificar.maximoItems) > 2) {
            sumLinea(1, (Math.ceil(item[index].length / col.justificar.maximoItems) - 2) * 20)
          }
        })

        if (lineas.cabecera1 >= pdf.internal.pageSize.height - 80) {
          pdf.addPage()
          lineas.cabecera1 = 30 // Restart height position
          lineas.cabecera2 = 30 // Restart height position

          pdf.roundedRect(
            40,
            lineas.cabecera1 + 12,
            520,
            (datos.detalles.cuerpo.length - ix) * 20 <= 380
              ? 776 - datos.pie.totales.length * 20 - (lineas.cabecera1 + 20)
              : pdf.internal.pageSize.height - 50 - lineas.cabecera1,
            3,
            3,
          )
          pdf.roundedRect(40, lineas.cabecera1 + 32, 520, 0, 0, 0)
          pdf.setFont('Helvetica', 'bold')
          sumLinea(1, 25)
          datos.detalles.cabecera.forEach(item => {
            pdf.text(item.texto, item.posicion, sumLinea(1, 0), item.alineacion)
          })
        }
      })

      if (lineas.cabecera1 >= pdf.internal.pageSize.height - datos.pie.totales.length * 20 - 80) {
        pdf.addPage()
        lineas.cabecera1 = 30 // Restart height position
        lineas.cabecera2 = 30 // Restart height position

        pdf.roundedRect(
          40,
          lineas.cabecera1 + 12,
          520,
          776 - datos.pie.totales.length * 20 - (lineas.cabecera1 + 20),
          3,
          3,
        )
        pdf.roundedRect(40, lineas.cabecera1 + 32, 520, 0, 0, 0)
        pdf.setFont('Helvetica', 'bold')
        sumLinea(1, 25)
        datos.detalles.cabecera.forEach(item => {
          pdf.text(item.texto, item.posicion, sumLinea(1, 0), item.alineacion)
        })
      }

      /////////////////////////////////////////////////////////////////////////////////////

      lineas.cabecera1 = 776 - datos.pie.totales.length * 20
      lineas.cabecera2 = 776 - datos.pie.totales.length * 20

      //pdf.roundedRect(40, lineas.cabecera1, 270, 776 - lineas.cabecera1 , 6, 6)
      pdf.text(datos.pie.nota.texto + ' ' + datos.pie.nota.valor, 45, lineas.cabecera1 + 16, {
        maxWidth: 250,
        align: 'justify',
      })

      pdf.setFont('Helvetica', 'bold')
      pdf.setFontSize(10)

      datos.pie.totales.forEach(item => {
        pdf.roundedRect(420, lineas.cabecera2, 140, 20, 0, 0)
        pdf.text(item.texto, 415, lineas.cabecera2 + 16, 'right')
        pdf.text(item.valor, 555, lineas.cabecera2 + 16, 'right')
        sumLinea(2, 20)
      })

      const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages()
        doc.setFont('helvetica', 'italic')
        doc.setFontSize(8)

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          doc.text('Pagina ' + String(i) + ' de ' + String(pageCount), 40, doc.internal.pageSize.height - 10, {
            align: 'left',
          })

          pdf.text(
            store.state.user.nombre + ' ' + store.state.user.apellido + ' ' + moment().format('MM/DD/YYYY, h:mm:ss a'),
            doc.internal.pageSize.width - 40,
            doc.internal.pageSize.height - 10,
            'right',
          )
        }
      }

      addFooters(pdf)
      pdf.save(`${datos.nombreDocumento}.pdf`)
    }

    return {
      generarPDF,
      sumLinea,
    }
  },
}
</script>