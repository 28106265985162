<template>
  <span>
    <v-btn v-if="tipoBtn == 1" color="info" small @click="GetPdf()" :loading="cargandoDatos" :disabled="!id">
      <v-icon left small>
        {{ icons.mdiFilePdfBox }}
      </v-icon>
      PDF
    </v-btn>

    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <a class="pr-1" v-bind="attrs" v-on="on" @click="GetPdf()">
          <v-icon color="primary">{{ icons.mdiFilePdfBox }}</v-icon>
        </a>
      </template>
      <span>PDF</span>
    </v-tooltip>
    <Pdf ref="pdfRef"></Pdf>
  </span>
</template>
<script>
import TransformacionServices from '@/api/servicios/inventario/TransformacionServices'
import { mdiFilePdfBox } from '@mdi/js'
import { ref } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import Pdf from '@/components/PdfFormatoLibreDocumentos.vue'
export default {
  props: {
    id: Number,
    tipoBtn: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Pdf,
  },
  setup(props) {
    const cargandoDatos = ref(false)
    const pdfRef = ref(null)
    const GetPdf = () => {
      cargandoDatos.value = true

      TransformacionServices.transformacionListarPaginado({ id: props.id })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            const datoInit = response.data.datos.datos[0]
            const cuerpoTablaDetalles = []

            datoInit.productos.forEach(item => {
              cuerpoTablaDetalles.push([
                item.producto.id.toString(),
                item.producto.nombre,
                `${FuncionesGenerales.formatoNumeric(item.cantidad)} ${item.producto.unidad_medida.unidad}`,
                FuncionesGenerales.formatoNumeric(item.relacion_costo),
                `${FuncionesGenerales.formatoNumeric(item.cantidad_real)} ${datoInit.producto.unidad_medida.unidad}`,
                `${FuncionesGenerales.formatoNumeric(item.porcentaje_costo, 0)}%`,
                `$${FuncionesGenerales.formatoNumeric(item.costo_real)}`,
                `$${FuncionesGenerales.formatoNumeric(item.costo_total)}`,
              ])
            })

            const datosInit = {
              cabecera1: { visible: true },
              nombreDocumento: `Transformacion: ${datoInit.numero_documento}`,

              numeroDocumento: {
                texto: 'Transformacion:',
                valor: datoInit.id.toString(),
              },
              numeroControl: {
                texto: 'Doc Nro',
                valor: datoInit.numero_documento,
              },
              numeroFecha: {
                texto: 'Fecha Transformacion',
                valor: FuncionesGenerales.formatoFecha(datoInit.fecha_transformacion, 1),
              },
              sub1: {
                linea1: [
                  { texto: 'Area:', valor: datoInit.puesto.nombre },
                  { texto: 'Almacen Origen:', valor: datoInit.almacen_origen.descripcion },
                  { texto: 'Almacen Destino:', valor: datoInit.almacen_destino.descripcion },
                ],
                linea2: [
                  {
                    texto: 'Creado por:',
                    valor: datoInit.empleado_crea.nombre + ' ' + datoInit.empleado_crea.apellido,
                  },
                  { texto: 'Fecha Creacion:', valor: FuncionesGenerales.formatoFecha(datoInit.fecha_creacion, 3) },
                  { texto: 'Estado:', valor: datoInit.estado.descripcion },
                ],
              },
              sub2: {
                linea1: [
                  { texto: 'Producto Transformado', valor: '' },
                  { texto: 'Id:', valor: datoInit.id.toString() },
                  { texto: 'Nombre:', valor: datoInit.producto.nombre },
                  { texto: 'Costo:', valor: `$${FuncionesGenerales.formatoNumeric(datoInit.costo, 2)}` }, 
                  { texto: 'Cantidad:', valor: `${FuncionesGenerales.formatoNumeric(datoInit.cantidad_total, 2)} ${datoInit.producto.unidad_medida.unidad}` }, 
                ],
                linea2: [ 
                ],
              },
              /*
                        datoInit.id,
                        datoInit.producto.nombre,
                        datoInit.producto.unidad_medida.unidad,
                        `$${FuncionesGenerales.formatoNumeric(datoInit.costo, 2)}`  ,
                        `${FuncionesGenerales.formatoNumeric(datoInit.cantidad_total, 2)} ${datoInit.producto.unidad_medida.unidad}`  ,
                        `$${FuncionesGenerales.formatoNumeric(datoInit.costo_total, 2)}`  ,
                        `${FuncionesGenerales.formatoNumeric(datoInit.cantidad_total - datoInit.cantidad_desperdicio, 2)} ${datoInit.producto.unidad_medida.unidad}`  ,
                        `${FuncionesGenerales.formatoNumeric(datoInit.cantidad_desperdicio, 2)} ${datoInit.producto.unidad_medida.unidad}`  ,
              */
              pie: {
                nota: {
                  texto: 'Descripcion:',
                  valor: datoInit.descripcion,
                },
                totales: [
                  {
                    texto: 'Items:',
                    valor: cuerpoTablaDetalles.length.toString(),
                  },
                  {
                    texto: 'Cantidad',
                    valor: `${FuncionesGenerales.formatoNumeric(datoInit.cantidad_total, 2)} ${datoInit.producto.unidad_medida.unidad}`,
                  },
                  {
                    texto: 'Aprovechado',
                    valor: `${FuncionesGenerales.formatoNumeric(datoInit.cantidad_total - datoInit.cantidad_desperdicio, 2)} ${datoInit.producto.unidad_medida.unidad}`,
                  },
                  {
                    texto: 'Desperdicio',
                    valor: `(${datoInit.porcentaje_desperdicio}%) ${FuncionesGenerales.formatoNumeric(datoInit.cantidad_desperdicio, 2)} ${datoInit.producto.unidad_medida.unidad}`,
                  },
                  {
                    texto: 'Costo Total',
                    valor: `$${FuncionesGenerales.formatoNumeric(datoInit.costo_total, 2)}`,
                  },
                ],
              },

              detalles: {
                cabecera: [
                  { texto: 'Id', posicion: '45', alineacion: 'left' },
                  {
                    texto: 'Producto',
                    posicion: '80',
                    alineacion: 'left',
                    justificar: { maxWidth: 130, align: 'justify', maximoItems: 33 },
                  },
                  { texto: 'Cant', posicion: '260', alineacion: 'right' },
                  { texto: 'Relac Cant', posicion: '340', alineacion: 'right' },
                  { texto: 'Cant Real', posicion: '400', alineacion: 'right' },
                  { texto: '%Costo', posicion: '450', alineacion: 'right' },
                  { texto: 'Costo', posicion: '500', alineacion: 'right' },
                  { texto: 'Total Coto', posicion: '555', alineacion: 'right' },
                ],
                cuerpo: cuerpoTablaDetalles,
              },
            }
            /*
            const datosInit = {
              nombreDocumento: `Transformacion: ${datoInit.numero_documento}`,

              cabecera1: {
                visible: true,
              },
              titulo: {
                visible: true,
                posicion: 'center',
                texto: 'Transformacion',
                TamanoLetras: 14,
                color: '#7B7B7B',
              },
              cabecera2: {
                visible: true,
                datos: [
                  { texto: '# ' + datoInit.numero_documento, TamanoLetras: 14, color: '#FF0000' },
                  { texto: 'Fecha Creacion: ' + FuncionesGenerales.formatoFecha(datoInit.fecha_creacion, 1) },
                  {
                    texto:
                      'Hora Creacion: ' +
                      FuncionesGenerales.formatoFecha(datoInit.fecha_creacion, 2)
                        .replace(/\u00a0/g, '')
                        .replace(/\u202f/g, ''),
                  },
                  { texto: 'Empleado: ' + datoInit.empleado_crea.nombre + ' ' + datoInit.empleado_crea.apellido },
                ],
              },
              sub1: {
                visible: true,
                datos: [
                  {
                    texto: 'Fecha Transformacion: ' + FuncionesGenerales.formatoFecha(datoInit.fecha_transformacion, 1),
                  }, 
                  { texto: 'Puesto: ' + datoInit.puesto.nombre },
                  { texto: 'Almacen Origen: ' + datoInit.almacen_origen.descripcion },
                  { texto: 'Almacen Destino: ' + datoInit.almacen_destino.descripcion },
                ],
              },
              sub2: {
                visible: true,
                datos: [
                  { texto: 'Estado:' + datoInit.estado.descripcion },
                  { texto: 'Descripcion:' },
                  { texto: datoInit.descripcion },
                ],
              },

              cuerpo1: {
                visible: true,
                datos: [
                {
                    titulo: { texto: 'Producto Transformado', TamanoLetras: 12 },
                    columnStyles: {
                      3: {
                        halign: 'right',
                      },
                      4: {
                        halign: 'right',
                      },
                      5: {
                        halign: 'right',
                      },
                     6: {
                        halign: 'right',
                      },
                      7: {
                        halign: 'right',
                      },
                    },
                    tablas: {},
                    head: [
                      [
                        { content: 'id', styles: { halign: 'left' } },
                        { content: 'Producto', styles: { halign: 'left' } },
                        { content: 'Medida', styles: { halign: 'left' } },
                        { content: 'Costo', styles: { halign: 'right' } },
                        { content: 'Cantidad', styles: { halign: 'right' } },
                        { content: 'Costo Total', styles: { halign: 'right' } },
                        { content: 'Aprovechado', styles: { halign: 'right' } },
                        { content: 'Desperdicio', styles: { halign: 'right' } },
                      ],
                    ],
                    body: [
                      [
                        datoInit.id,
                        datoInit.producto.nombre,
                        datoInit.producto.unidad_medida.unidad,
                        `$${FuncionesGenerales.formatoNumeric(datoInit.costo, 2)}`  ,
                        `${FuncionesGenerales.formatoNumeric(datoInit.cantidad_total, 2)} ${datoInit.producto.unidad_medida.unidad}`  ,
                        `$${FuncionesGenerales.formatoNumeric(datoInit.costo_total, 2)}`  ,
                        `${FuncionesGenerales.formatoNumeric(datoInit.cantidad_total - datoInit.cantidad_desperdicio, 2)} ${datoInit.producto.unidad_medida.unidad}`  ,
                        `${FuncionesGenerales.formatoNumeric(datoInit.cantidad_desperdicio, 2)} ${datoInit.producto.unidad_medida.unidad}`  ,
                      ],
                    ],
                  },
                  {
                    titulo: { texto: 'Resultado', TamanoLetras: 12 },
                    columnStyles: {
                      3: {
                        halign: 'right',
                      },
                    },
                    tablas: {},
                    head: [
                      [
                        { content: 'id', styles: { halign: 'left' } },
                        { content: 'Producto', styles: { halign: 'left' } },
                        { content: 'Medida', styles: { halign: 'left' } },
                        { content: 'Cantidad', styles: { halign: 'right' } },
                        { content: 'Relacion Cant', styles: { halign: 'right' } },
                        { content: 'Cant Real', styles: { halign: 'right' } },
                        { content: '% Costo', styles: { halign: 'right' } },
                        
                        { content: 'Costo Und', styles: { halign: 'right' } },
                        { content: 'Costo Total', styles: { halign: 'right' } },
                      ],
                    ],
                    body: cuerpoTablaDetalles,
                  },
                 
                ],
              },
            }
*/
            pdfRef.value.generarPDF(datosInit)
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargandoDatos.value = false
        })
    }

    return {
      GetPdf,
      cargandoDatos,
      pdfRef,
      icons: {
        mdiFilePdfBox,
      },
    }
  },
}
</script>