<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Transformacion </v-card-subtitle>
    <v-form ref="form" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="6">
                <BuscarProducto
                  :label="`Producto`"
                  @GetProducto="GetProducto"
                  ref="RefProducto"
                  :filtros="{ id_puesto: store.state.puestoSelect.id_puesto }"
                />

                <SelectAlmacen
                  class="mt-4"
                  :dense="true"
                  :outlined="true"
                  :add="false"
                  v-model="datos.almacen_origen" 
              :filtro="{ id_puesto: store.state.puestoSelect.id_puesto} "
                  :label="'Almacen Origen'"
                />
                <SelectAlmacen
                  class="mt-4"
                  :dense="true"
                  :outlined="true"
                  :add="false"
                  v-model="datos.almacen_destino"
                  :label="'Almacen Destino'" 
              :filtro="{ id_puesto: store.state.puestoSelect.id_puesto} "
                />
                <v-textarea v-model="datos.descripcion" class="mt-4" label="Descripcion" dense outlined></v-textarea>
              </v-col>
              <v-col cols="12" lg="6">
                <DatePicker
                  v-model="datos.fecha_transformacion"
                  :dense="true"
                  :outlined="true"
                  :label="'Fecha Transformacion'"
                />
                <v-text-field
                  :value="datos.costo"
                  prefix="$"
                  readonly
                  outlined
                  dense
                  hide-details="auto"
                  label="Costo"
                ></v-text-field>
                <v-text-field
                  prefix="$"
                  :value="datos.costo_total"
                  readonly
                  outlined
                  dense
                  class="mt-4"
                  hide-details="auto"
                  label="Costo Total"
                ></v-text-field>
                <v-text-field
                  :suffix="datos.producto.unidad_medida.descripcion"
                  v-model="datos.cantidad"
                  outlined
                  dense
                  class="mt-4"
                  hide-details="auto"
                  label="Cantidad"
                  @keyup="calcularCosto()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-divider></v-divider>
    <v-card-subtitle> Detalle </v-card-subtitle>
    <v-divider></v-divider>

    <v-card-text>
      <v-form ref="formDetalle" lazy-validation>
        <v-row class="mb-4 mt-2">
          <v-col cols="12">
            <BuscarProducto
              :label="`Producto detalle`"
              @GetProducto="GetProductoDetalle"
              ref="RefProductoDetalle"
              :filtros="{ id_puesto: store.state.puestoSelect.id_puesto }"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Id</th>
              <th class="text-left">Producto</th>
              <th class="text-left">Cantidad</th>
              <th class="text-left">Relacion</th>
              <th class="text-left">Real</th>
              <th class="text-left">% Costo</th>
              <th class="text-left">Costo Und</th>
              <th class="text-left">Costo Total</th>
              <th class="text-left">-</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in datos.productos" :key="index">
              <td>
                {{ item.producto.id }}
              </td>
              <td>
                <strong> {{ item.producto.nombre }} </strong> <br />
                <small>{{ item.producto.unidad_medida.descripcion }}</small>
              </td>
              <td>
                <v-text-field
                  style="width: 100px"
                  class="mt-1"
                  hide-details=""
                  v-model="item.cantidad"
                  dense
                  outlined
                  :suffix="` ${item.producto.unidad_medida.unidad}`"
                  @keyup="calcularCostoDetalle()"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  :suffix="`1 => ${item.producto.unidad_medida.unidad}`"
                  :prefix="`${datos.producto.unidad_medida.unidad}`"
                  style="width: 150px"
                  class="mt-1"
                  hide-details=""
                  v-model="item.relacion_costo"
                  dense
                  outlined
                  @keyup="calcularCostoDetalle()"
                ></v-text-field>
              </td>
              <td>{{ item.relacion_costo * item.cantidad }} {{ datos.producto.unidad_medida.unidad }}</td>
              <td>
                <v-text-field
                  suffix="%"
                  style="width: 100px"
                  class="mt-1"
                  hide-details=""
                  v-model="item.porcentaje_costo"
                  dense
                  outlined
                  @keyup="calcularCostoDetalle()"
                ></v-text-field>
              </td>
              <td>{{ item.costo_real.toFixed(4) }}</td>
              <td>{{ item.costo_total.toFixed(4) }}</td>
              <td>
                <v-btn icon color="error" small @click="eliminarProdcuto(item)">
                  <v-icon small>{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row>
        <v-col cols="8"> </v-col>
        <v-col cols="4">
          <v-simple-table dense class="mt-6">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Cantidad</td>
                  <td>{{ datos.cantidad }} {{ datos.producto.unidad_medida.unidad }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Aprovechado</td>
                  <td>{{ datos.cantidad - cantidiadDisponible() }} {{ datos.producto.unidad_medida.unidad }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Desperdicio</td>
                  <td>{{ cantidiadDisponible() }} {{ datos.producto.unidad_medida.unidad }}</td>
                  <td v-if="datos.cantidad">{{ ((cantidiadDisponible() / datos.cantidad) * 100).toFixed(0) }}%</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div class="text-center">
            <v-btn color="secondary" class="me-3" @click="limpiar()" small> Limpiar </v-btn>
            <v-btn color="primary" class="me-3" small @click="Guardar()"> Guardar </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import DatePicker from '@/components/DatePicker.vue'
import BuscarProducto from '@/views/sistema/inventario/producto/componentes/BuscarProductoExistencia.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectAlmacenJson from '@/views/sistema/inventario/maestro/almacen/componentes/SelectJson.vue'
import funciones from '@/funciones/funciones'
import TooltipInfo from '@/components/TooltipInfo.vue'
import { mdiInformation, mdiDelete, mdiPencil } from '@mdi/js'
import TransformacionServices from '@/api/servicios/inventario/TransformacionServices'

import SelectMotivo from './SelectMotivo.vue'
export default {
  watch: {
    '$store.state.puestoSelect.id_puesto': function () {
      this.limpiar()
    },
  },
  components: {
    DatePicker,
    SelectEstado,
    SelectAlmacen,
    BuscarProducto,
    TooltipInfo,
    SelectAlmacenJson,
    SelectMotivo,
  },
  setup(props, context) {
    const guardando = ref(false)
    const form = ref(null)
    const formDetalle = ref(null)

    const RefProductoDetalle = ref(null)

    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
      numerico_requerido: [
        v => !!v || 'Es requerido',
        v => !isNaN(v) || 'No es numero valido',
        v => !(v == Infinity) || 'No es numero valido 2',
      ],
    }
    const datosInit = {
      id: -1,
      almacen_origen: null,
      almacen_destino: null,
      descripcion: '',
      costo: 0,
      costo_total: 0,
      id_producto: '',
      id_puesto: '',
      cantidad: 0,
      cantidad_disponible: 0,
      productos: [],
      producto: {
        costo: 0,
        unidad_medida: {
          unidad: '',
          descripcion: '',
        },
      },
      id_empleado: null,
    }
    const itemAddInit = {
      producto: {},
      id_producto: null,
      cantidad: 0,
      cantidad_real: 0,
      relacion_costo: 1,
      costo_real: 0,
      costo_total: 0,
      porcentaje_costo: 100,
      diferencia_costo: 0,
      diferencia_real: 0,
    }
    const filtrarAlmacenCosto = item => {
      let exis = {}
      exis = item.existencias.find(it => it.id_almacen == datos.value.almacen)

      return exis
    }
    const datos = ref(
      JSON.parse(
        JSON.stringify({
          ...datosInit,
          id_puesto: store.state.puestoSelect.id_puesto,
          id_empleado: store.state.user.id_empleado,
        }),
      ),
    )
    const limpiar = () => {
      datos.value = JSON.parse(
        JSON.stringify({
          ...datosInit,
          id_puesto: store.state.puestoSelect.id_puesto,
          id_empleado: store.state.user.id_empleado,
        }),
      )
      itemAdd.value = JSON.parse(JSON.stringify(itemAddInit))

      form.value.resetValidation()
    }
    let editando = false

    const eliminarProdcuto = item => {
      datos.value.productos = datos.value.productos.filter(ele => ele.producto.id != item.producto.id)
      calcularCosto()
    }
    const itemAdd = ref(JSON.parse(JSON.stringify(itemAddInit)))
    const cargandos = ref(false)

    const GetProducto = item => {
      console.log('GetProducto', item)
      if (editando == false) {
        if (item != undefined) {
          datos.value.id_producto = item.id
          datos.value.costo = item.costo
          datos.value.producto = {
            id: item.id,
            nombre: item.nombre,
            costo: item.costo,
            existencias: item.existencias,
            unidad_medida: item.unidad_medida,
          }
          calcularCosto()
        }
      } else {
        editando = false
      }
    }

    const GetProductoDetalle = item => {
      console.log('GetProductoDetalle', item)
      if (editando == false) {
        if (item != undefined) {
          itemAdd.value = {
            ...itemAddInit,
            id_producto: item.id,
            cantidad: 0,
            cantidad_real: 0,
            relacion_costo: 1,
            porcentaje_costo: 100,
            producto: {
              id: item.id,
              nombre: item.nombre,
              costo: item.costo,
              existencias: item.existencias,
              unidad_medida: item.unidad_medida,
            },
          }
          agregarDetalle()
        }
      } else {
        editando = false
      }
    }
    const CargarEditar = item => {
      datos.value = {
        ...item,
        fecha_transformacion: funciones.formatoFecha(item.fecha_transformacion, 4),
      }
      goTo(0)
    }
    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }

    const agregarDetalle = () => {
      if (datos.value.productos.find(item => item.producto.id == itemAdd.value.producto.id) == undefined) {
        datos.value.productos.push({ ...itemAdd.value })
        itemAdd.value = JSON.parse(JSON.stringify(itemAddInit))
        RefProductoDetalle.value.limpiar()

        calcularCostoDetalle()
      } else {
        store.commit('setAlert', {
          message: 'Ya existe el producto en la factura',
          type: 'warning',
        })
      }
    }
    const changeCantidades = (item, tipo) => {
      switch (tipo) {
        case 'cantidad':
          item.final = Number(item.actual) + Number(item.cantidad)
          break
        case 'final':
          item.cantidad = Number(item.final) - Number(item.actual)
          break
      }
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }

    const calcularCosto = () => {
      let sum = 0
      datos.value.costo_total = datos.value.costo * datos.value.cantidad
      console.log(sum)
      calcularCostoDetalle()
    }

    const cantidiadDisponible = () => {
      let sum = 0
      datos.value.productos.forEach(item => {
        sum += item.cantidad_real
      })

      return datos.value.cantidad - sum
    }

    const calcularCostoDetalle = () => {
      datos.value.productos.forEach(element => {
        element.cantidad_real = element.cantidad * element.relacion_costo
      })

      let total = datos.value.cantidad - cantidiadDisponible()
     
      let total_procentaje_editado = 0
      console.log('calcularCostoDetalle', total)
      datos.value.costo_real = datos.value.costo_total / total
      // calculo neto de todos los costos
      datos.value.productos.forEach(element => {
        element.costo = datos.value.costo_real * (element.porcentaje_costo / 100)
      })

     
      // recalcular costos 100% con diferencias de costo diferences a 100
      datos.value.productos.forEach(element => {
        if (element.porcentaje_costo != 100) {
          total_procentaje_editado += element.cantidad_real

          element.diferencia_costo = datos.value.costo_real - element.costo
          element.diferencia_costo_cantidad = element.diferencia_costo * element.cantidad_real
        } else {
          element.diferencia_costo = 0
        }
      })
      let total_cantidad_base = total - total_procentaje_editado
      //paso 2
      let sum_diferencias = 0
      datos.value.productos.forEach(element => {
        if (element.porcentaje_costo != 100) {
          element.diferencia_real = element.diferencia_costo_cantidad / total_cantidad_base
          sum_diferencias += element.diferencia_real
        }
      })

      datos.value.productos.forEach(element => {
        if (element.porcentaje_costo == 100) {
          element.costo = element.costo + sum_diferencias
        }

        element.costo_total = element.costo * element.cantidad_real
        element.costo_real = element.costo * element.relacion_costo
      })
    }

    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        try {
          

          TransformacionServices.TransformacionActualizar({
            ...datos.value,
            id_puesto: store.state.puestoSelect.id_puesto,
            cantidad_desperdicio: cantidiadDisponible(),
            porcentaje_desperdicio: ((cantidiadDisponible() / datos.value.cantidad) * 100).toFixed(2),
          })
            .then(response => {
              console.log(response)
              if (response.data.mensaje == 'GUARDADO CON EXITO') {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
                ActualizarLista(response.data.datos)
                limpiar()
              } else {
                store.commit('setAlert', {
                  message: `${response.data.mensaje}`,
                  type: 'warning',
                })
              }
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'guardar',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) { 
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: error,
            funcion: 'guardar',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    return {
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
      store,
      GetProducto,
      GetProductoDetalle,
      RefProductoDetalle,
      itemAdd,
      formDetalle,
      agregarDetalle,
      icons: {
        mdiInformation,
        mdiDelete,
        mdiPencil,
      },
      eliminarProdcuto,
      funciones,
      filtrarAlmacenCosto,
      changeCantidades,
      calcularCosto,
      calcularCostoDetalle,
      cantidiadDisponible,
    }
  },
}
</script>